import { TagType } from "@/api/enums";

import { sprengConnectApi } from "../sprengConnectApi";
import { PaginationResponse, SprengConnectApiResponse, SprengnetterIndexResponse } from "../types";
import {
  CreateIndexLinkedDocumentRequest,
  CreateIndexRequest,
  CreateInstitutionRequest,
  CreateRoleRequest,
  CreateUserRequest,
  FeatureAccessHistoryResponse,
  FeatureResponse,
  GetFeatureAccessHistoryParameters,
  GetIndexLinkedDocumentParameters,
  GetIndexesParameters,
  GetInstitutionsParameters,
  GetRolesParameters,
  IndexLinkedDocumentResponse,
  InstitutionResponse,
  RoleResponse,
  UpdateIndexLinkedDocumentRequest,
  UpdateInstitutionRequest,
  UpdateRoleRequest,
  UpdateUserRequest,
  UserResponse,
} from "../types/backOfficeController";
import { GetFeaturesParameters } from "../types/backOfficeController/request/GetFeaturesParameters";
import { GetUsersParameters } from "../types/backOfficeController/request/GetUsersParameters";
import { UpdateIndexRequest } from "../types/backOfficeController/request/UpdateIndexRequest";

export const backOfficeController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<SprengConnectApiResponse<UserResponse>, void>({
      query: () => ({
        url: "BackOffice/GetMe",
        method: "GET",
      }),
    }),

    getUsers: builder.query<PaginationResponse<UserResponse> | undefined, GetUsersParameters>({
      query: (query) => ({
        url: "BackOffice/GetUsers?" + createGetUsersQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_UserResponse, id }))
          : [TagType.SprengConnectApi_UserResponse],
    }),
    createUser: builder.mutation<SprengConnectApiResponse<UserResponse> | undefined, CreateUserRequest>({
      query: (body) => ({
        url: "BackOffice/CreateUser",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagType.SprengConnectApi_UserResponse],
    }),
    updateUser: builder.mutation<
      SprengConnectApiResponse<UserResponse> | undefined,
      { userId: string; request: UpdateUserRequest }
    >({
      query: (body) => ({
        url: "BackOffice/UpdateUser/" + body.userId,
        method: "PUT",
        data: body.request,
      }),
      invalidatesTags: (result) =>
        result?.data
          ? [{ type: TagType.SprengConnectApi_UserResponse, id: result.data.id }]
          : [TagType.SprengConnectApi_UserResponse],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: "BackOffice/DeleteUser/" + userId,
        method: "DELETE",
      }),
      invalidatesTags: [TagType.SprengConnectApi_UserResponse],
    }),

    getInstitutions: builder.query<PaginationResponse<InstitutionResponse> | undefined, GetInstitutionsParameters>({
      query: (query) => ({
        url: "BackOffice/GetInstitutions?" + createGetInstitutionsQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_InstitutionResponse, id }))
          : [TagType.SprengConnectApi_InstitutionResponse],
    }),
    createInstitution: builder.mutation<
      SprengConnectApiResponse<InstitutionResponse> | undefined,
      CreateInstitutionRequest
    >({
      query: (body) => ({
        url: "BackOffice/CreateInstitution",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagType.SprengConnectApi_InstitutionResponse],
    }),
    updateInstitution: builder.mutation<
      SprengConnectApiResponse<InstitutionResponse> | undefined,
      { institutionId: string; request: UpdateInstitutionRequest }
    >({
      query: (body) => ({
        url: "BackOffice/UpdateInstitution/" + body.institutionId,
        method: "PUT",
        data: body.request,
      }),
      invalidatesTags: (result) =>
        result?.data
          ? [{ type: TagType.SprengConnectApi_InstitutionResponse, id: result.data.id }]
          : [TagType.SprengConnectApi_InstitutionResponse],
    }),
    deleteInstitution: builder.mutation<void, string>({
      query: (institutionId) => ({
        url: "BackOffice/DeleteInstitution/" + institutionId,
        method: "DELETE",
      }),
      invalidatesTags: [TagType.SprengConnectApi_InstitutionResponse],
    }),

    getRoles: builder.query<PaginationResponse<RoleResponse> | undefined, GetRolesParameters>({
      query: (query) => ({
        url: "BackOffice/GetRoles?" + createGetRolesQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_RoleResponse, id }))
          : [TagType.SprengConnectApi_RoleResponse],
    }),
    createRole: builder.mutation<SprengConnectApiResponse<RoleResponse> | undefined, CreateRoleRequest>({
      query: (body) => ({
        url: "BackOffice/CreateRole",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagType.SprengConnectApi_RoleResponse],
    }),
    updateRole: builder.mutation<
      SprengConnectApiResponse<RoleResponse> | undefined,
      { roleId: string; request: UpdateRoleRequest }
    >({
      query: (body) => ({
        url: "BackOffice/UpdateRole/" + body.roleId,
        method: "PUT",
        data: body.request,
      }),
      invalidatesTags: (result) =>
        result?.data
          ? [{ type: TagType.SprengConnectApi_RoleResponse, id: result.data.id }]
          : [TagType.SprengConnectApi_RoleResponse],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (roleId) => ({
        url: "BackOffice/DeleteRole/" + roleId,
        method: "DELETE",
      }),
      invalidatesTags: [TagType.SprengConnectApi_RoleResponse],
    }),

    getFeatureAccessHistories: builder.query<
      PaginationResponse<FeatureAccessHistoryResponse> | undefined,
      GetFeatureAccessHistoryParameters
    >({
      query: (query) => ({
        url: "BackOffice/GetFeatureAccessHistory?" + createGetFeatureAccessHistoriesQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_FeatureAccessHistoryResponse, id }))
          : [TagType.SprengConnectApi_FeatureAccessHistoryResponse],
    }),
    getFeatures: builder.query<PaginationResponse<FeatureResponse> | undefined, GetFeaturesParameters>({
      query: (query) => ({
        url: "BackOffice/GetFeatures?" + createGetFeaturesQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_FeatureResponse, id }))
          : [TagType.SprengConnectApi_FeatureResponse],
    }),

    getIndexes: builder.query<PaginationResponse<SprengnetterIndexResponse> | undefined, GetIndexesParameters>({
      query: (query) => ({
        url: "BackOffice/GetIndexes?" + createGetIndexesQuery(query),
        method: "GET",
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_IndexResponse, id }))
          : [TagType.SprengConnectApi_IndexResponse],
    }),
    createIndex: builder.mutation<SprengConnectApiResponse<SprengnetterIndexResponse> | undefined, CreateIndexRequest>({
      query: (body) => ({
        url: "BackOffice/CreateIndex",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagType.SprengConnectApi_IndexResponse],
    }),
    updateIndex: builder.mutation<
      SprengConnectApiResponse<SprengnetterIndexResponse> | undefined,
      { indexId: string; request: UpdateIndexRequest }
    >({
      query: (body) => ({
        url: "BackOffice/UpdateIndex/" + body.indexId,
        method: "PUT",
        data: body.request,
      }),
      invalidatesTags: (result) =>
        result?.data
          ? [{ type: TagType.SprengConnectApi_IndexResponse, id: result.data.id }]
          : [TagType.SprengConnectApi_IndexResponse],
    }),
    deleteIndex: builder.mutation<void, string>({
      query: (indexId) => ({
        url: "BackOffice/DeleteIndex/" + indexId,
        method: "DELETE",
      }),
      invalidatesTags: [TagType.SprengConnectApi_IndexResponse],
    }),

    getIndexLinkedDocuments: builder.query<
      PaginationResponse<IndexLinkedDocumentResponse> | undefined,
      GetIndexLinkedDocumentParameters
    >({
      query: (query) => ({
        url: "BackOffice/GetIndexLinkedDocument?" + createGetIndexLinkedDocumentQuery(query),
        method: "GET",
        params: query,
      }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ id }) => ({ type: TagType.SprengConnectApi_IndexLinkedDocumentResponse, id }))
          : [TagType.SprengConnectApi_IndexLinkedDocumentResponse],
    }),
    createIndexLinkedDocument: builder.mutation<
      SprengConnectApiResponse<IndexLinkedDocumentResponse>,
      CreateIndexLinkedDocumentRequest
    >({
      query: (body) => ({
        url: "BackOffice/CreateIndexLinkedDocument",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagType.SprengConnectApi_IndexLinkedDocumentResponse],
    }),
    updateIndexLinkedDocument: builder.mutation<
      SprengConnectApiResponse<IndexLinkedDocumentResponse>,
      { documentId: string; request: UpdateIndexLinkedDocumentRequest }
    >({
      query: (body) => ({
        url: "BackOffice/UpdateIndexLinkedDocument/" + body.documentId,
        method: "PUT",
        data: body.request,
      }),
      invalidatesTags: (result) =>
        result?.data
          ? [{ type: TagType.SprengConnectApi_IndexLinkedDocumentResponse, id: result.data.id }]
          : [TagType.SprengConnectApi_IndexLinkedDocumentResponse],
    }),
    deleteIndexLinkedDocument: builder.mutation<void, string>({
      query: (documentId) => ({
        url: "BackOffice/DeleteIndexLinkedDocument/" + documentId,
        method: "DELETE",
      }),
      invalidatesTags: [TagType.SprengConnectApi_IndexLinkedDocumentResponse],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetInstitutionsQuery,
  useCreateInstitutionMutation,
  useUpdateInstitutionMutation,
  useDeleteInstitutionMutation,
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetFeatureAccessHistoriesQuery,
  useGetIndexesQuery,
  useCreateIndexMutation,
  useUpdateIndexMutation,
  useDeleteIndexMutation,
  useGetIndexLinkedDocumentsQuery,
  useCreateIndexLinkedDocumentMutation,
  useUpdateIndexLinkedDocumentMutation,
  useDeleteIndexLinkedDocumentMutation,

  useLazyGetFeatureAccessHistoriesQuery,
  useGetFeaturesQuery,
  useLazyGetFeaturesQuery,
} = backOfficeController;

const createGetUsersQuery = (params: GetUsersParameters): string => {
  let query = "";
  if (params.firstName) query += `FirstName=${params.firstName}`;
  if (params.lastName) {
    if (query) query += "&";
    query += `LastName=${params.lastName}`;
  }
  if (params.status) {
    if (query) query += "&";
    query += `Status=${params.status}`;
  }
  if (params.institutionId) {
    if (query) query += "&";
    query += `InstitutionId=${params.institutionId}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  if (params.pageNumber) {
    if (query) query += "&";
    query += `PageNumber=${params.pageNumber}`;
  }
  if (params.pageSize) {
    if (query) query += "&";
    query += `PageSize=${params.pageSize}`;
  }
  return query;
};

const createGetInstitutionsQuery = (params: GetInstitutionsParameters): string => {
  let query = "";
  if (params.name) query += `Name=${params.name}`;
  if (params.identificationNumber) {
    if (query) query += "&";
    query += `IdentificationNumber=${params.identificationNumber}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  if (params.pageNumber) {
    if (query) query += "&";
    query += `PageNumber=${params.pageNumber}`;
  }
  if (params.pageSize) {
    if (query) query += "&";
    query += `PageSize=${params.pageSize}`;
  }
  return query;
};

const createGetRolesQuery = (params: GetRolesParameters): string => {
  let query = "";
  if (params.name) query += `Name=${params.name}`;
  if (params.description) {
    if (query) query += "&";
    query += `Description=${params.description}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  if (params.pageNumber) {
    if (query) query += "&";
    query += `PageNumber=${params.pageNumber}`;
  }
  if (params.pageSize) {
    if (query) query += "&";
    query += `PageSize=${params.pageSize}`;
  }
  if (params.include) {
    if (query) query += "&";
    query += params.include.map((i) => `Include=${i}`).join("&");
  }
  return query;
};

const createGetFeatureAccessHistoriesQuery = (params: GetFeatureAccessHistoryParameters): string => {
  let query = "";
  if (params.featureId) query += `FeatureId=${params.featureId}`;
  if (params.userId) {
    if (query) query += "&";
    query += `UserId=${params.userId}`;
  }
  if (params.isAccess != null) {
    if (query) query += "&";
    query += `IsAccess=${params.isAccess}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  if (params.pageNumber) {
    if (query) query += "&";
    query += `PageNumber=${params.pageNumber}`;
  }
  if (params.pageSize) {
    if (query) query += "&";
    query += `PageSize=${params.pageSize}`;
  }
  return query;
};

const createGetFeaturesQuery = (params: GetFeaturesParameters): string => {
  let query = "";
  if (params.name) query += `Name=${params.name}`;
  if (params.description) {
    if (query) query += "&";
    query += `Description=${params.description}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  return query;
};

const createGetIndexesQuery = (params: GetIndexesParameters): string => {
  let query = "";
  if (params.title) query += `Name=${params.title}`;
  if (params.shortTitle) {
    if (query) query += "&";
    query += `Description=${params.shortTitle}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  return query;
};

const createGetIndexLinkedDocumentQuery = (params: GetIndexLinkedDocumentParameters): string => {
  let query = "";
  if (params.name) query += `Name=${params.name}`;
  if (params.indexId) {
    if (query) query += "&";
    query += `IndexId=${params.indexId}`;
  }
  if (params.sortDirection) {
    if (query) query += "&";
    query += `SortDirection=${params.sortDirection}`;
  }
  if (params.sortBy) {
    if (query) query += "&";
    query += `SortBy=${params.sortBy}`;
  }
  return query;
};
