import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { PageType } from "@/types/pageTypes";
import { SprengnetterIndexOverviewItemResponse } from "@/api/sprengConnectApi";

// Define a type for the slice state
export interface ApplicationMainSliceState {
  currentPage: PageType;
  selectedIndex?: SprengnetterIndexOverviewItemResponse;
  authenticated?: boolean;
  isUserFeatureNotAvailableDialogOpen: boolean;
}

// Define the initial state using that type
const initialState: ApplicationMainSliceState = {
  currentPage: PageType.Login,
  selectedIndex: undefined,
  authenticated: false,
  isUserFeatureNotAvailableDialogOpen: false,
};

// Implement the slice
export const applicationMainSlice = createSlice({
  name: "applicationMain",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<PageType>) => {
      state.currentPage = action.payload;
    },
    setSelectedIndex: (state, action: PayloadAction<SprengnetterIndexOverviewItemResponse>) => {
      state.selectedIndex = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
    setUserFeatureNotAvailableDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isUserFeatureNotAvailableDialogOpen = action.payload;
    }
  },
});

export const { setCurrentPage, setSelectedIndex, setAuthenticated, setUserFeatureNotAvailableDialogOpen } = applicationMainSlice.actions;

export const PagesReducer = applicationMainSlice.reducer;
