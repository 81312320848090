export enum TagType {
  // #region Spreng Connect Api
  SprengConnectApi_PingResult = "SprengConnectApi_PingResult",
  SprengConnectApi_SprengnetterIndexOverviewItemResponse = "SprengConnectApi_SprengnetterIndexOverviewItemResponse",
  SprengConnectApi_IndexChartDataResponse = "SprengConnectApi_IndexChartDataResponse",
  SprengConnectApi_InstitutionResponse = "SprengConnectApi_InstitutionResponse",
  SprengConnectApi_RoleResponse = "SprengConnectApi_RoleResponse",
  SprengConnectApi_UserResponse = "SprengConnectApi_UserResponse",
  SprengConnectApi_IndexResponse = "SprengConnectApi_IndexResponse",
  SprengConnectApi_IndexLinkedDocumentResponse = "SprengConnectApi_IndexLinkedDocumentResponse",
  SprengConnectApi_FeatureAccessHistoryResponse = "SprengConnectApi_FeatureAccessHistoryResponse",
  SprengConnectApi_FeatureResponse = "SprengConnectApi_FeatureResponse"

  // #endregion Spreng Connect Api
}
