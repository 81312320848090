export enum PageType {
  Login = "Login",
  LandingPage = "LandingPage",
  PortfolioAnalysis = "PortfolioAnalysis",
  Playground = "Playground",
  AdminLogin = "AdminLogin",
  BackOfficeUser = "BackOfficeUser",
  BackOfficeInstitution = "BackOfficeInstitution",
  BackOfficeSubscription = "BackOfficeSubscription",
  BackOfficeUserHistory = "BackOfficeUserHistory",
  BackOfficeIndices = "BackOfficeIndices",
}
