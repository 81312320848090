import { Box, IconButton, LinearProgress, SvgIconProps, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./translations";

import { CheckIcon, CloseIcon, LoadingIcon } from "@/assets";
import { setAnalysisData } from "@/pages/portfolioAnalysisPage/portfolioAnalysisPageSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { PortfolioAnalysisState } from "@/types";

import { ConfirmDialog } from "../confirmDialog";

const icon: Record<PortfolioAnalysisState, (props: SvgIconProps) => JSX.Element> = {
  [PortfolioAnalysisState.AnalysisCompleted]: CheckIcon,
  [PortfolioAnalysisState.AnalysisInProgress]: LoadingIcon,
  [PortfolioAnalysisState.FileUploaded]: CheckIcon,
  [PortfolioAnalysisState.FileUploading]: LoadingIcon,
  [PortfolioAnalysisState.AnalysisFailed]: CheckIcon,
};

export type FileUploadTextboxProps = {
  fileName: string;
  analysisState: PortfolioAnalysisState;
  sx?: SxProps<Theme>;
  showIcon?: boolean;
};

export const FileUploadTextbox = ({ fileName, analysisState, showIcon, sx }: FileUploadTextboxProps): JSX.Element => {
  const { t } = useTranslation("FileUploadTextbox");
  const dispatch = useAppDispatch();

  const analysisData = useAppSelector((state) => state.portfolioAnalysisPage.analysisData);
  const [showSecureCancelAnalysisDialog, setShowSecureCancelAnalysisDialog] = useState<boolean>(false);
  const isInProgress = [PortfolioAnalysisState.FileUploading, PortfolioAnalysisState.AnalysisInProgress].includes(
    analysisState,
  );

  const handleSecureCancelAnalysis = (decision: boolean): void => {
    if (!analysisData) return;
    if (decision) {
      dispatch(
        setAnalysisData({
          ...analysisData,
          analysisState: PortfolioAnalysisState.AnalysisCompleted,
        }),
      );
    }
    setShowSecureCancelAnalysisDialog(false);
  };

  const handleOnCancelAnalsisClick = (): void => {
    setShowSecureCancelAnalysisDialog(true);
  };

  const Icon = icon[analysisState];
  return (
    <>
      <ConfirmDialog
        confirmText={t("cancelAnalysisDialog.confirm")}
        message={t("cancelAnalysisDialog.message")}
        onDecision={handleSecureCancelAnalysis}
        open={showSecureCancelAnalysisDialog}
        title={t("cancelAnalysisDialog.title")}
        cancelText={t("cancelAnalysisDialog.cancel")}
      />
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 2,
          borderColor: (theme) => theme.palette.uploadFildIndexSelection.light,
          minHeight: "36px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          ...sx,
        }}
      >
        <Box
          sx={{
            height: "100%",
            ml: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="uploadFileName">{fileName}</Typography>
          {analysisState === PortfolioAnalysisState.AnalysisInProgress ? (
            <Typography sx={{ ml: "10px", mr: "30px" }} variant="uploadFileStatusRunning">
              {t("portfolioAnalysisIsInProgress")}
            </Typography>
          ) : analysisState === PortfolioAnalysisState.AnalysisFailed ? (
            <Typography sx={{ ml: "10px", mr: "30px" }} variant="uploadFileStatusFailed">
              {t("portfolioAnalysisIsFailed")}
            </Typography>
          ) : (
            analysisState === PortfolioAnalysisState.AnalysisCompleted && (
              <Typography sx={{ ml: "10px", mr: "30px" }} variant="uploadFileStatusCompleted">
                {t("portfolioAnalysisIsCompleted")}
              </Typography>
            )
          )}
          {showIcon && (
            <Box sx={{ mt: "5px", mr: "10px" }}>
              <Icon />
            </Box>
          )}
          {analysisState === PortfolioAnalysisState.AnalysisInProgress && analysisData != null && (
            <IconButton sx={{ mr: "10px" }} onClick={handleOnCancelAnalsisClick}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <LinearProgress
          variant={isInProgress ? "indeterminate" : "determinate"}
          sx={{ alignItems: "flex-end", height: "3px" }}
        />
      </Box>
    </>
  );
};
