import { PaletteColorOptions, Theme, createTheme, darken } from "@mui/material";
import { LinkProps } from "@mui/material/Link";

import { ThemeColors } from "../base";
import { LinkBehavior } from "../behaviors";
import { scrollBarWidth } from "../constants";

const themeColors: ThemeColors = {
  primaryLight: "#EAF5FF",
  primaryMain: "#005CA9",
  /* Sprengnetter Web Blau 100 */
  SprengnetterWebBlau100: "#E0F0FF",
  /* Sprengnetter Web Blau 300 */
  SprengnetterWebBlau300: "#A8D0F7",
  /* Sprengnetter Schwarzgrau 900 */
  SprengnetterSchwarzgrau900: "#4A4A49",
};

const paletteColors: Record<string, PaletteColorOptions> = {
  primary: {
    light: themeColors.primaryLight,
    main: themeColors.primaryMain,
    dark: "#1D4E8C",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: themeColors.SprengnetterWebBlau100,
    dark: "#C4E0FC",
    contrastText: "#005CA9",
  },
  success: {
    main: "#20C997",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FFC107",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#DC3545",
    contrastText: "#FFFFFF",
    dark: "#922D37",
    light: "#FFC5CA",
  },
  invited: {
    main: "#F3F5F7",
    contrastText: themeColors.SprengnetterSchwarzgrau900,
  },
};

const borderRadius: Record<string, number> = {
  small: 4,
  medium: 8,
  large: 12,
};

export const LightTheme = (...args: object[]): Theme =>
  createTheme(
    {
      themeColors,
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
        values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536, xxl: 1900 },
      },
      palette: {
        mode: "light",
        primary: paletteColors.primary,
        secondary: paletteColors.secondary,
        success: paletteColors.success,
        warning: paletteColors.warning,
        error: paletteColors.error,
        invited: {
          main: "#F3F5F7",
          contrastText: themeColors.SprengnetterSchwarzgrau900,
          light: "#F3F5F7", //not used
          dark: "#F3F5F7", //not used
        },
        uploadFildIndexSelection: {
          main: "#DEE2E6",
          contrastText: "#E3E3E3",
          light: "#F3F5F7", //not used
          dark: "#922D37", //not used
        },
      },
      sidebar: {
        background: "#1E5396",
        textColor: "#FFFFFF",
        selectedTextColor: "#1E5396",
        selectedBackgroundStartColor: "#C8B085",
        selectedBackgroundColor: "#F1F1F1",
        drawerWidth: 100,
        fontFamility: "Open Sans",
        fontWeight: "700",
        fontSize: "11px",
        lineHeight: "140%",
        letterSpacing: "-0.011em",
      },
      typography: {
        fontFamily: ["Open Sans", "Inter", "Lato"].join(","),
        h1: {
          color: themeColors.SprengnetterSchwarzgrau900,
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "28px",
          fontFamily: "Inter",
        },
        h2: {
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        h3: {
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        h4: {
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        h5: {
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        h6: {
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        body1: {
          color: "#000000",
          fontFamily: "Open Sans",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
        },
        body2: {
          fontSize: 12,
          color: "#aaa",
        },
        sidebarItem: {
          fontFamily: "Open Sans",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          textAlign: "center",
        },
        button: {
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "19px",
          textAlign: "center",
          textTransform: "none",
        },
        indexCardTitle: {
          fontFamily: "Inter",
          fontSize: "20px",
          fontWeight: "700",
          lineHeight: "24.2px",
          textAlign: "left",
        },
        indexCardListItem: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "23px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        playgroundAnalysisSwitchTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "24px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        playgroundAnalysisSwitchTitleLink: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "24px",
          color: "#005CA9",
        },
        pageSectionTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "32px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        menuTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "24px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        pageSubSectionTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        headerMenuTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "16px",
          color: "#000000",
        },
        headerMenuSubTitle: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#000000",
        },
        headerMenuSectionTitle: {
          display: "block",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16px",
          color: "#000000",
        },
        headerMenuSectionText: {
          display: "block",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "16px",
          color: "#000000",
        },
        uploadDropFieldError: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "20px",
          color: "#922D37",
        },
        uploadDropFieldDescription1: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          color: "#333333",
        },
        uploadDropFieldDescription2: {
          fontFamily: "Inter",
          fontStyle: "bold",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          color: "#005CA9",
        },
        uploadDropFieldSupportedFileTypes: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "12px",
          color: "#676767",
        },
        uploadFileTableHeader: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#4A4A49",
        },
        uploadFileName: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          color: "#0F0F0F",
        },
        welcome: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "28px",
          color: "#4A4A49",
          textAlign: "center",
        },
        welcomeFooter: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "10px",
          color: "#4A4A49",
        },
        loginHeader: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "36px",
          color: "#4A4A49",
          textAlign: "center",
        },
        loginRegistrationDescription: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          color: "#4A4A49",
          textAlign: "center",
        },
        loginFieldLabel: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "16px",
          color: "#4A4A49",
        },
        welcomeOr: {
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "25px",
          color: "#4B4B5A",
        },
        tableValue: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "22px",
          color: themeColors.SprengnetterSchwarzgrau900,
        },
        tableSubValue: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "23px",
          color: "#75BBFF",
        },
        uploadFileStatusRunning: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          color: "#FFA600",
        },
        uploadFileStatusFailed: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          color: "#FF0000",
        },
        uploadFileStatusCompleted: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          color: "#01990B",
        },
        tooltipText: {
          color: "black",
          fontFamily: "SourceSans3",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "16px",
        }
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 1px 4px 0px #0000001A",
              height: "63px",
              "& .MuiToolbar-root": {
                minHeight: "unset",
              },
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              /* width */
              "*::-webkit-scrollbar": {
                width: scrollBarWidth,
              },
              /* Track */
              "*::-webkit-scrollbar-track": {
                background: "transparent",
              },
              /* Handle */
              "*::-webkit-scrollbar-thumb": {
                height: "40px",
                border: `5px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
              /* Handle on hover */
              "*::-webkit-scrollbar-thumb:hover": {
                border: `2px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
              "*::-webkit-scrollbar-corner": {
                background: "transparent",
              },
            },
            body: {
              backgroundColor: "#F1F1F1",
            },
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
        MuiButton: {
          styleOverrides: {
            startIcon: {
              marginRight: "5px",
              marginLeft: "5px",
            },
            endIcon: {
              marginRight: "5px",
              marginLeft: "5px",
            },
          },
          variants: [
            {
              props: { size: "medium" },
              style: {
                padding: "9px 12px 9px 12px",
                gap: "10px",
                borderRadius: borderRadius.small,
                "&:hover": {
                  padding: "9px 12px 9px 12px",
                  gap: "10px",
                  borderRadius: borderRadius.small,
                },
              },
            },
            {
              props: { size: "large" },
              style: {
                padding: "9px 16px 9px 16px",
                gap: "10px",
                borderRadius: borderRadius.small,
                "&:hover": {
                  padding: "9px 16px 9px 16px",
                  gap: "10px",
                  borderRadius: borderRadius.small,
                },
              },
            },
          ],
        },
        MuiCard: {
          variants: [
            {
              props: { variant: "cardObjectTile" },
              style: {
                "&.MuiCard-root": {
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  },
                },
              },
            },
            {
              props: { variant: "loadingCardObjectTile" },
              style: {
                "&.MuiCard-root": {
                  background: "#FFFFFF",
                  borderRadius: "10px",
                },
              },
            },
            {
              props: { variant: "pageSection" },
              style: {
                "&.MuiCard-root": {
                  padding: "20px 17px",
                  gap: "20px",
                  background: "#FFFFFF",
                  borderRadius: "0px",
                },
                "& .MuiCardHeader-root": {
                  padding: 0,
                },
                "& .MuiCardContent-root": {
                  padding: 0,
                  width: "100%",
                  "&:last-child": {
                    padding: 0,
                  },
                },
                "& .MuiCardHeader-action": {
                  margin: 0,
                },
              },
            },
          ],
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: "100px",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "16px",
              textAlign: "right",
            },
          },
          variants: [
            {
              props: { color: "inactive" },
              style: {
                background: "#ADB5BC",
                color: "#FFFFFF",
              },
            },
            {
              props: { color: "primary" },
              style: {
                color: "#FFFFFF",
              },
            },
            {
              props: { color: "invited" },
              style: {
                color: themeColors.SprengnetterSchwarzgrau900,
                background: "#F3F5F7",
              },
            },
            {
              props: { variant: "playgroundFilterChip" },
              style: {
                border: "1px solid #DEE2E6",
                borderRadius: "4px",
                background: "#FFFFFF",
                color: themeColors.primaryMain,
                "& .MuiChip-deleteIcon": {
                  color: "#75BBFF",
                  "&:hover": {
                    color: darken("#75BBFF", 0.2),
                  },
                },
              },
            },
          ],
        },
        CheckboxField: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              padding: "12px 13px",
              margin: 0,
              width: "100%",
              gap: "10px",
              borderRadius: "4px",
              background: ownerState.checked ? theme.themeColors.SprengnetterWebBlau100 : "#FFFFFF",
              border: ownerState.checked
                ? `1px solid ${theme.themeColors.SprengnetterWebBlau300}`
                : "1px solid #FFFFFF",
            }),
          },
        },
        RadioField: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              padding: "12px 13px",
              margin: 0,
              width: "100%",
              gap: "10px",
              borderRadius: "4px",
              background: ownerState.selected ? theme.themeColors.SprengnetterWebBlau100 : "#FFFFFF",
              border: ownerState.selected
                ? `1px solid ${theme.themeColors.SprengnetterWebBlau300}`
                : "1px solid #FFFFFF",
            }),
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: "#FFFFFF",
              border: "1px solid #CCD0DC",
              color: "black",
              margin: "6px",
              padding: "8px",
              fontFamily: "SourceSans3",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "16px",
              boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.5)",
              "&&::after": {
                content: '"\\A"',
                whiteSpace: "pre",
                display: "block",
              },
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: themeColors.primaryLight,
              },
              "&:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              border: "none",
            },
          },
        },
      },
    },
    ...args,
  );
