import { Box, Button, ButtonGroup, Card, Chip, LinearProgress, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
// import { saveAs } from "file-saver";
// import { Layout, newPlot } from "plotly.js-dist-min";
// import Plotly from "plotly.js-basic-dist";
import Plotly from "plotly.js/lib/core";
import "plotly.js/lib/scatter";
import { RefObject, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon, SprengnetterLogo } from "@/assets";
import { calcReferenceYearValues } from "@/helpers/playgroundChatHelper";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ChartSeries } from "@/types";
import { ChartSeriesValue } from "@/types/playground/ChartSeriesValue";

import { setReferenceYear } from "../playgroundPageSlice";

// const buttonStyle: React.CSSProperties = {
//   padding: "8px 12px",
//   fontSize: "14px",
//   backgroundColor: "#007bff",
//   color: "white",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

enum QuickYears {
  threeY = "threeY",
  fiveY = "fiveY",
  tenY = "tenY",
  all = "all",
}

export type ChartAreaRef = {
  getChartAreaRef: () => RefObject<HTMLDivElement>;
};

type ChartAreaProps = {
  chartSeries: ChartSeries[];
  isChartDataLoading: boolean;
};

export const ChartArea = forwardRef<ChartAreaRef, ChartAreaProps>(
  ({ chartSeries, isChartDataLoading }, ref): JSX.Element => {
    const dispatch = useAppDispatch();
    const plotRef = useRef<HTMLDivElement>(null);
    const [currentRange, setCurrentRange] = useState<QuickYears>(QuickYears.all);
    const { t } = useTranslation("PlaygroundPage");

    const chartDeadlineInformation = useAppSelector((state) => state.playgroundPage.chartDeadlineInformation);
    const referenceYear = useAppSelector((state) => state.playgroundPage.referenceYear);

    const [referenceYearUserInput, setReferenceYearUserInput] = useState<DateTime | null>(referenceYear);

    useImperativeHandle(ref, () => ({
      getChartAreaRef: (): RefObject<HTMLDivElement> => plotRef,
    }));

    const onChangeReferenceYear = (date: DateTime | null): void => {
      setReferenceYearUserInput(date);
    };

    const onCommitReferenceYear = (): void => {
      if (!referenceYearUserInput) return;
      dispatch(setReferenceYear(referenceYearUserInput));
    };

    const layout: Partial<Plotly.Layout> = useMemo(
      () => ({
        xaxis: { title: "Jahr", showgrid: true, gridcolor: "#e5e5e5", type: "date" },
        yaxis: { title: "Index", showgrid: true, gridcolor: "#e5e5e5" },
        showlegend: true,
        legend: {
          orientation: "h",
          yanchor: "bottom",
          xanchor: "center",
          y: -0.3,
          x: 0.5,
        },
        paper_bgcolor: "#ffffff",
        plot_bgcolor: "#ffffff",
        images: [
          {
            xref: "paper" as const,
            yref: "paper" as const,
            x: 1,
            y: -0.2,
            sizex: 0.2,
            sizey: 0.2,
            opacity: 0.6,
          },
        ],
      }),
      [],
    );

    useEffect(() => {
      const currentPlotRef = plotRef.current;
      if (!currentPlotRef) return;
      Plotly.newPlot(
        currentPlotRef,
        chartSeries.map((chartSeries) => {
          const valuesToUse = chartSeries.values.filter(
            (value): value is Required<ChartSeriesValue> => value.y != null,
          );

          return {
            x: valuesToUse.map((value) => value.x),
            y: valuesToUse.map((value) => value.y),
            hovertemplate: `${chartSeries.name}<br >%{x|%Y}: %{y:,.0f}<br >Seit ${referenceYear.year}: Detail: %{customdata}%<extra></extra>`,
            type: "scatter",
            mode: "lines+markers",
            name: chartSeries.name,
            line: { shape: "spline" },
            customdata: calcReferenceYearValues(referenceYear.year, chartSeries),
          };
        }),
        layout,
        {
          displaylogo: false,
          modeBarButtonsToRemove: ["toImage", "lasso2d", "select2d"],
          responsive: true,
          autosizable: true,
          displayModeBar: true,
        },
      );
    }, [chartSeries, currentRange, layout, referenceYear.year]);

    const handleZoomReset = (): void => {
      Plotly.relayout(plotRef.current!, { "xaxis.autorange": true, "yaxis.autorange": true });
    };

    // const handleDownloadImage = (): void => {
    //   Plotly.toImage(plotRef.current!, { format: "png", width: 800, height: 600 }).then((dataUrl) => {
    //     saveAs(dataUrl, "chart.png");
    //   });
    // };

    // const handleDownloadCSV = (): void => {
    //   let csvContent = "data:text/csv;charset=utf-8,Year,Value\n";
    //   data.forEach((d) => {
    //     d.x.forEach((xValue, i) => {
    //       csvContent += `${xValue},${d.y[i]}\n`;
    //     });
    //   });
    //   const encodedUri = encodeURI(csvContent);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   link.setAttribute("download", "chart_data.csv");
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // };

    const handleSetRange = (range: QuickYears) => (): void => {
      const lastYear = chartSeries
        .map((chartSerie) => chartSerie.values[chartSerie.values.length - 1].year)
        .reduce((acc, cur) => Math.max(acc, cur), 0);
      // const lastYear = parseInt(data[0].x[data[0].x.length - 1]);

      if (range !== QuickYears.all) {
        let startYear;
        switch (range) {
          case QuickYears.threeY:
            startYear = lastYear - 3;
            break;
          case QuickYears.fiveY:
            startYear = lastYear - 5;
            break;
          case QuickYears.tenY:
            startYear = lastYear - 10;
            break;
          default:
            return;
        }

        Plotly.relayout(plotRef.current!, { "xaxis.range": [startYear.toString(), lastYear.toString()] });
      } else {
        handleZoomReset();
      }
      setCurrentRange(range);
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", mt: "20px" }}>
        {isChartDataLoading && <LinearProgress />}
        <Card variant="pageSection" sx={{ position: "relative" }}>
          <Box
            sx={(theme) => ({
              display: "flex",
              position: "absolute",
              top: theme.spacing(2),
              left: theme.spacing(2),
              zIndex: 2,
            })}
          >
            {Object.entries(QuickYears).map(([, value]) => (
              <Chip
                key={value}
                label={t("chart.quickYears.options", { context: value })}
                onClick={handleSetRange(value)}
                sx={{ mr: 1 }}
                color={currentRange === value ? "primary" : "default"}
              />
            ))}
          </Box>
          <div ref={plotRef} style={{ width: "100%", height: "500px" }}></div>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ButtonGroup>
                <DatePicker
                  label={t("chart.referenceYear.title")}
                  views={["year"]}
                  value={referenceYear}
                  onChange={onChangeReferenceYear}
                  maxDate={DateTime.now()}
                />
                <Tooltip title={t("chart.referenceYear.commitTooltip")} arrow>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={referenceYearUserInput == null}
                    onClick={onCommitReferenceYear}
                  >
                    <CheckIcon
                      sx={(theme) => ({
                        "--arrow-color": theme.palette.primary.contrastText,
                      })}
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <Typography variant="uploadFileName">{t("chart.referenceYear.hint")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
              <SprengnetterLogo sx={{ width: "159px", height: "54px" }} />
              <Typography variant="uploadFileName">{t("chart.sourceInformation")}</Typography>
              {chartDeadlineInformation && (
                <Typography variant="uploadFileName">
                  {t("chart.deadline", { deadline: chartDeadlineInformation })}
                </Typography>
              )}
            </Box>
          </Box>
          {/* <div style={{ marginTop: "10px", display: "flex", gap: "8px" }}>
        <button onClick={() => handleSetRange("3y")} style={buttonStyle}>
          3Y
        </button>
        <button onClick={() => handleSetRange("5y")} style={buttonStyle}>
          5Y
        </button>
        <button onClick={() => handleSetRange("10y")} style={buttonStyle}>
          10Y
        </button>
        <button onClick={() => handleSetRange("all")} style={buttonStyle}>
          All
        </button>
        <button onClick={handleZoomReset} style={buttonStyle}>
          Reset Zoom
        </button>
        <button onClick={handleDownloadImage} style={buttonStyle}>
          Download as Image
        </button>
        <button onClick={handleDownloadCSV} style={buttonStyle}>
          Download as CSV
        </button>
      </div> */}
        </Card>
      </Box>
    );
  },
);
