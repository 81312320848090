import { SprengnetterIndexOverviewItemResponse } from "@/api/sprengConnectApi";

export enum TimeRange {
  OneYear = "OneYear",
  ThreeYears = "ThreeYears",
}

export type PortfolioAnalysisPageFilter = {
  selectedIndexes: SprengnetterIndexOverviewItemResponse[];
  selectedTimeRanges: TimeRange[];
}
