import { ObjectSchema, array, number, object, string } from "yup";

import { AssignFeatureToRoleResponse } from "@/api/sprengConnectApi";
import { RoleUpsertDto } from "@/types";

export const assignedFeaturesSchema: ObjectSchema<AssignFeatureToRoleResponse> = object().shape({
  featureId: string().required("REQUIRED_FIELD"),
  limit: number().optional(),
  featureName: string().required("REQUIRED_FIELD"),
});

export const createSubscriptionRequestSchema: ObjectSchema<RoleUpsertDto> = object().shape({
  name: string().required("REQUIRED_FIELD"),
  description: string().required("REQUIRED_FIELD"),
  value: string().required(),
  assignedFeatures: array().of(assignedFeaturesSchema).optional(),
});
