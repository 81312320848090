import { i18n } from "i18next";

import { de as buttonsDe, en as buttonsEn } from "./buttons";
import { de as errorsDe, en as errorsEn } from "./errors";
import { de as pagesDe, en as pagesEn } from "./pages";
import { de as typesDe, en as typesEn } from "./types";

export const namespace = "Common";

const de = {
  pages: pagesDe,
  errors: errorsDe,
  buttons: buttonsDe,
  types: typesDe,
};

const en = {
  pages: pagesEn,
  errors: errorsEn,
  buttons: buttonsEn,
  types: typesEn,
};

declare module "i18next" {
  // defining custom interface, which can be extended inside each index of translation folder.
  // this is needed, because not possible to redefine every time types of properties
  interface CustomTypeResources {
    [namespace]: typeof de;
  }

  interface CustomTypeOptions {
    defaultNS: typeof namespace;
    // custom resources type
    resources: CustomTypeResources;
  }
}

export const addCommonResourceBundles = (i18n: i18n): void => {
  i18n.addResourceBundle("de", namespace, de, true, true);
  i18n.addResourceBundle("en", namespace, en, true, true);
};

export * from "./errors/errorEnums";
