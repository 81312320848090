import { IndexIdentifier } from "@/api/sprengConnectApi";
import { ObjectType } from "@/types";

export type IndexCompareValue = {
  year: number;
  month?: number;
  value: number;
}
export type IndexCategoryValue = Record<ObjectType, IndexCompareValue[]>;

const simData: IndexCategoryValue = {
  [ObjectType.OneFamilyDwelling]: [
    { "year": 1987, "value": 59 },
    { "year": 1988, "value": 63 },
    { "year": 1989, "value": 68 },
    { "year": 1990, "value": 72 },
    { "year": 1991, "value": 78 },
    { "year": 1992, "value": 84 },
    { "year": 1993, "value": 88 },
    { "year": 1994, "value": 93 },
    { "year": 1995, "value": 96 },
    { "year": 1996, "value": 97 },
    { "year": 1997, "value": 98 },
    { "year": 1998, "value": 99 },
    { "year": 1999, "value": 100 },
    { "year": 2000, "value": 102 },
    { "year": 2001, "value": 103 },
    { "year": 2002, "value": 104 },
    { "year": 2003, "value": 102 },
    { "year": 2004, "value": 101 },
    { "year": 2005, "value": 100 },
    { "year": 2006, "value": 101 },
    { "year": 2007, "value": 104 },
    { "year": 2008, "value": 106 },
    { "year": 2009, "value": 108 },
    { "year": 2010, "value": 108 },
    { "year": 2011, "value": 111 },
    { "year": 2012, "value": 114 },
    { "year": 2013, "value": 116 },
    { "year": 2014, "value": 121 },
    { "year": 2015, "value": 128 },
    { "year": 2016, "value": 136 },
    { "year": 2017, "value": 148 },
    { "year": 2018, "value": 160 },
    { "year": 2019, "value": 170 },
    { "year": 2020, "value": 181 },
    { "year": 2021, "value": 197 },
    { "year": 2022, "value": 225 },
    { "year": 2023, "value": 225 }
  ],
  [ObjectType.OwnerOccupiedDwelling]: [
    { "year": 1987, "value": 64 },
    { "year": 1988, "value": 68 },
    { "year": 1989, "value": 74 },
    { "year": 1990, "value": 80 },
    { "year": 1991, "value": 86 },
    { "year": 1992, "value": 93 },
    { "year": 1993, "value": 98 },
    { "year": 1994, "value": 102 },
    { "year": 1995, "value": 106 },
    { "year": 1996, "value": 104 },
    { "year": 1997, "value": 103 },
    { "year": 1998, "value": 104 },
    { "year": 1999, "value": 103 },
    { "year": 2000, "value": 104 },
    { "year": 2001, "value": 105 },
    { "year": 2002, "value": 104 },
    { "year": 2003, "value": 103 },
    { "year": 2004, "value": 101 },
    { "year": 2005, "value": 100 },
    { "year": 2006, "value": 101 },
    { "year": 2007, "value": 102 },
    { "year": 2008, "value": 102 },
    { "year": 2009, "value": 103 },
    { "year": 2010, "value": 104 },
    { "year": 2011, "value": 107 },
    { "year": 2012, "value": 110 },
    { "year": 2013, "value": 113 },
    { "year": 2014, "value": 119 },
    { "year": 2015, "value": 127 },
    { "year": 2016, "value": 137 },
    { "year": 2017, "value": 147 },
    { "year": 2018, "value": 159 },
    { "year": 2019, "value": 170 },
    { "year": 2020, "value": 183 },
    { "year": 2021, "value": 199 },
    { "year": 2022, "value": 223 },
    { "year": 2023, "value": 221 }
  ],
  [ObjectType.MultiFamilyDwelling]: [
    { "year": 1987, "value": 64 },
    { "year": 1988, "value": 68 },
    { "year": 1989, "value": 74 },
    { "year": 1990, "value": 80 },
    { "year": 1991, "value": 86 },
    { "year": 1992, "value": 93 },
    { "year": 1993, "value": 98 },
    { "year": 1994, "value": 102 },
    { "year": 1995, "value": 106 },
    { "year": 1996, "value": 104 },
    { "year": 1997, "value": 103 },
    { "year": 1998, "value": 104 },
    { "year": 1999, "value": 103 },
    { "year": 2000, "value": 104 },
    { "year": 2001, "value": 105 },
    { "year": 2002, "value": 104 },
    { "year": 2003, "value": 103 },
    { "year": 2004, "value": 101 },
    { "year": 2005, "value": 100 },
    { "year": 2006, "value": 101 },
    { "year": 2007, "value": 102 },
    { "year": 2008, "value": 103 },
    { "year": 2009, "value": 105 },
    { "year": 2010, "value": 106 },
    { "year": 2011, "value": 107 },
    { "year": 2012, "value": 108 },
    { "year": 2013, "value": 110 },
    { "year": 2014, "value": 112 },
    { "year": 2015, "value": 116 },
    { "year": 2016, "value": 124 },
    { "year": 2017, "value": 134 },
    { "year": 2018, "value": 145 },
    { "year": 2019, "value": 155 },
    { "year": 2020, "value": 166 },
    { "year": 2021, "value": 180 },
    { "year": 2022, "value": 203 },
    { "year": 2023, "value": 201 }
  ],
};

const ipiData: IndexCategoryValue = {
  [ObjectType.OneFamilyDwelling]: [
    { year: 2012, month: 6, value: 0.984103 },
    { year: 2012, month: 7, value: 0.988309 },
    { year: 2012, month: 8, value: 0.991226 },
    { year: 2012, month: 9, value: 0.994405 },
    { year: 2012, month: 10, value: 0.997065 },
    { year: 2012, month: 11, value: 0.998315 },
    { year: 2012, month: 12, value: 0.998554 },
    { year: 2013, month: 1, value: 1.000000 },
    { year: 2013, month: 2, value: 1.006022 },
    { year: 2013, month: 3, value: 1.015401 },
    { year: 2013, month: 4, value: 1.026776 },
    { year: 2013, month: 5, value: 1.036646 },
    { year: 2013, month: 6, value: 1.045000 },
    { year: 2013, month: 7, value: 1.049549 },
    { year: 2013, month: 8, value: 1.052823 },
    { year: 2013, month: 9, value: 1.053863 },
    { year: 2013, month: 10, value: 1.055835 },
    { year: 2013, month: 11, value: 1.057157 },
    { year: 2013, month: 12, value: 1.060751 },
    { year: 2014, month: 1, value: 1.063834 },
    { year: 2014, month: 2, value: 1.068368 },
    { year: 2014, month: 3, value: 1.072533 },
    { year: 2014, month: 4, value: 1.078396 },
    { year: 2014, month: 5, value: 1.083983 },
    { year: 2014, month: 6, value: 1.089084 },
    { year: 2014, month: 7, value: 1.091886 },
    { year: 2014, month: 8, value: 1.092904 },
    { year: 2014, month: 9, value: 1.092721 },
    { year: 2014, month: 10, value: 1.094853 },
    { year: 2014, month: 11, value: 1.097144 },
    { year: 2014, month: 12, value: 1.100778 },
    { year: 2015, month: 1, value: 1.101275 },
    { year: 2015, month: 2, value: 1.104116 },
    { year: 2015, month: 3, value: 1.107107 },
    { year: 2015, month: 4, value: 1.113098 },
    { year: 2015, month: 5, value: 1.119971 },
    { year: 2015, month: 6, value: 1.128128 },
    { year: 2015, month: 7, value: 1.136297 },
    { year: 2015, month: 8, value: 1.141038 },
    { year: 2015, month: 9, value: 1.142489 },
    { year: 2015, month: 10, value: 1.144171 },
    { year: 2015, month: 11, value: 1.148870 },
    { year: 2015, month: 12, value: 1.156760 },
    { year: 2016, month: 1, value: 1.164356 },
    { year: 2016, month: 2, value: 1.172146 },
    { year: 2016, month: 3, value: 1.183069 },
    { year: 2016, month: 4, value: 1.194744 },
    { year: 2016, month: 5, value: 1.203436 },
    { year: 2016, month: 6, value: 1.204148 },
    { year: 2016, month: 7, value: 1.202884 },
    { year: 2016, month: 8, value: 1.204755 },
    { year: 2016, month: 9, value: 1.212917 },
    { year: 2016, month: 10, value: 1.222496 },
    { year: 2016, month: 11, value: 1.229239 },
    { year: 2016, month: 12, value: 1.231456 },
    { year: 2017, month: 1, value: 1.233279 },
    { year: 2017, month: 2, value: 1.239289 },
    { year: 2017, month: 3, value: 1.249498 },
    { year: 2017, month: 4, value: 1.261174 },
    { year: 2017, month: 5, value: 1.269242 },
    { year: 2017, month: 6, value: 1.274548 },
    { year: 2017, month: 7, value: 1.278914 },
    { year: 2017, month: 8, value: 1.286492 },
    { year: 2017, month: 9, value: 1.296061 },
    { year: 2017, month: 10, value: 1.307527 },
    { year: 2017, month: 11, value: 1.316208 },
    { year: 2017, month: 12, value: 1.326256 },
    { year: 2018, month: 1, value: 1.333904 },
    { year: 2018, month: 2, value: 1.344718 },
    { year: 2018, month: 3, value: 1.352179 },
    { year: 2018, month: 4, value: 1.358652 },
    { year: 2018, month: 5, value: 1.362781 },
    { year: 2018, month: 6, value: 1.370390 },
    { year: 2018, month: 7, value: 1.382570 },
    { year: 2018, month: 8, value: 1.395332 },
    { year: 2018, month: 9, value: 1.405363 },
    { year: 2018, month: 10, value: 1.414462 },
    { year: 2018, month: 11, value: 1.424218 },
    { year: 2018, month: 12, value: 1.435516 },
    { year: 2019, month: 1, value: 1.445618 },
    { year: 2019, month: 2, value: 1.458464 },
    { year: 2019, month: 3, value: 1.471089 },
    { year: 2019, month: 4, value: 1.484401 },
    { year: 2019, month: 5, value: 1.493305 },
    { year: 2019, month: 6, value: 1.502338 },
    { year: 2019, month: 7, value: 1.510800 },
    { year: 2019, month: 8, value: 1.521632 },
    { year: 2019, month: 9, value: 1.531781 },
    { year: 2019, month: 10, value: 1.541210 },
    { year: 2019, month: 11, value: 1.549217 },
    { year: 2019, month: 12, value: 1.561118 },
    { year: 2020, month: 1, value: 1.574479 },
    { year: 2020, month: 2, value: 1.588897 },
    { year: 2020, month: 3, value: 1.599345 },
    { year: 2020, month: 4, value: 1.610487 },
    { year: 2020, month: 5, value: 1.622948 },
    { year: 2020, month: 6, value: 1.636271 },
    { year: 2020, month: 7, value: 1.648922 },
    { year: 2020, month: 8, value: 1.659234 },
    { year: 2020, month: 9, value: 1.671880 },
    { year: 2020, month: 10, value: 1.687379 },
    { year: 2020, month: 11, value: 1.707235 },
    { year: 2020, month: 12, value: 1.728935 },
    { year: 2021, month: 1, value: 1.751148 },
    { year: 2021, month: 2, value: 1.777674 },
    { year: 2021, month: 3, value: 1.806088 },
    { year: 2021, month: 4, value: 1.838254 },
    { year: 2021, month: 5, value: 1.867854 },
    { year: 2021, month: 6, value: 1.896946 },
    { year: 2021, month: 7, value: 1.919471 },
    { year: 2021, month: 8, value: 1.941231 },
    { year: 2021, month: 9, value: 1.962174 },
    { year: 2021, month: 10, value: 1.991675 },
    { year: 2021, month: 11, value: 2.018330 },
    { year: 2021, month: 12, value: 2.046022 },
    { year: 2022, month: 1, value: 2.065877 },
    { year: 2022, month: 2, value: 2.087235 },
    { year: 2022, month: 3, value: 2.101804 },
    { year: 2022, month: 4, value: 2.115438 },
    { year: 2022, month: 5, value: 2.125611 },
    { year: 2022, month: 6, value: 2.133104 },
    { year: 2022, month: 7, value: 2.133693 },
    { year: 2022, month: 8, value: 2.126227 },
    { year: 2022, month: 9, value: 2.114331 },
    { year: 2022, month: 10, value: 2.096952 },
    { year: 2022, month: 11, value: 2.075177 },
    { year: 2022, month: 12, value: 2.051384 },
    { year: 2023, month: 1, value: 2.029518 },
    { year: 2023, month: 2, value: 2.015462 },
    { year: 2023, month: 3, value: 2.004939 },
    { year: 2023, month: 4, value: 1.998038 },
    { year: 2023, month: 5, value: 1.989253 },
    { year: 2023, month: 6, value: 1.978540 },
    { year: 2023, month: 7, value: 1.967332 },
    { year: 2023, month: 8, value: 1.958712 },
    { year: 2023, month: 9, value: 1.954609 },
    { year: 2023, month: 10, value: 1.950114 },
    { year: 2023, month: 11, value: 1.940476 },
    { year: 2023, month: 12, value: 1.930880 },
    { year: 2024, month: 1, value: 1.927549 },
    { year: 2024, month: 2, value: 1.934699 },
    { year: 2024, month: 3, value: 1.945343 },
    { year: 2024, month: 4, value: 1.953455 },
    { year: 2024, month: 5, value: 1.955639 },
    { year: 2024, month: 6, value: 1.950436 },
    { year: 2024, month: 7, value: 1.945028 },
    { year: 2024, month: 8, value: 1.941202 },
    { year: 2024, month: 9, value: 1.947600 }
  ],
  [ObjectType.OwnerOccupiedDwelling]: [
    { year: 2012, month: 6, value: 0.959845 },
    { year: 2012, month: 7, value: 0.963173 },
    { year: 2012, month: 8, value: 0.969704 },
    { year: 2012, month: 9, value: 0.976482 },
    { year: 2012, month: 10, value: 0.983262 },
    { year: 2012, month: 11, value: 0.988192 },
    { year: 2012, month: 12, value: 0.994635 },
    { year: 2013, month: 1, value: 1.000000 },
    { year: 2013, month: 2, value: 1.007800 },
    { year: 2013, month: 3, value: 1.013309 },
    { year: 2013, month: 4, value: 1.020398 },
    { year: 2013, month: 5, value: 1.026001 },
    { year: 2013, month: 6, value: 1.033139 },
    { year: 2013, month: 7, value: 1.038747 },
    { year: 2013, month: 8, value: 1.044088 },
    { year: 2013, month: 9, value: 1.047484 },
    { year: 2013, month: 10, value: 1.050343 },
    { year: 2013, month: 11, value: 1.053108 },
    { year: 2013, month: 12, value: 1.057538 },
    { year: 2014, month: 1, value: 1.062475 },
    { year: 2014, month: 2, value: 1.068943 },
    { year: 2014, month: 3, value: 1.075797 },
    { year: 2014, month: 4, value: 1.081942 },
    { year: 2014, month: 5, value: 1.087051 },
    { year: 2014, month: 6, value: 1.091981 },
    { year: 2014, month: 7, value: 1.097227 },
    { year: 2014, month: 8, value: 1.100969 },
    { year: 2014, month: 9, value: 1.102373 },
    { year: 2014, month: 10, value: 1.105535 },
    { year: 2014, month: 11, value: 1.108055 },
    { year: 2014, month: 12, value: 1.113391 },
    { year: 2015, month: 1, value: 1.117190 },
    { year: 2015, month: 2, value: 1.124948 },
    { year: 2015, month: 3, value: 1.133840 },
    { year: 2015, month: 4, value: 1.145012 },
    { year: 2015, month: 5, value: 1.155621 },
    { year: 2015, month: 6, value: 1.164338 },
    { year: 2015, month: 7, value: 1.171510 },
    { year: 2015, month: 8, value: 1.177305 },
    { year: 2015, month: 9, value: 1.181569 },
    { year: 2015, month: 10, value: 1.185817 },
    { year: 2015, month: 11, value: 1.190375 },
    { year: 2015, month: 12, value: 1.197330 },
    { year: 2016, month: 1, value: 1.206427 },
    { year: 2016, month: 2, value: 1.217814 },
    { year: 2016, month: 3, value: 1.231078 },
    { year: 2016, month: 4, value: 1.242393 },
    { year: 2016, month: 5, value: 1.250972 },
    { year: 2016, month: 6, value: 1.255583 },
    { year: 2016, month: 7, value: 1.259905 },
    { year: 2016, month: 8, value: 1.268479 },
    { year: 2016, month: 9, value: 1.280946 },
    { year: 2016, month: 10, value: 1.296820 },
    { year: 2016, month: 11, value: 1.308109 },
    { year: 2016, month: 12, value: 1.318280 },
    { year: 2017, month: 1, value: 1.327836 },
    { year: 2017, month: 2, value: 1.341111 },
    { year: 2017, month: 3, value: 1.354612 },
    { year: 2017, month: 4, value: 1.364546 },
    { year: 2017, month: 5, value: 1.371593 },
    { year: 2017, month: 6, value: 1.378070 },
    { year: 2017, month: 7, value: 1.386893 },
    { year: 2017, month: 8, value: 1.396822 },
    { year: 2017, month: 9, value: 1.406116 },
    { year: 2017, month: 10, value: 1.414859 },
    { year: 2017, month: 11, value: 1.422245 },
    { year: 2017, month: 12, value: 1.430206 },
    { year: 2018, month: 1, value: 1.439445 },
    { year: 2018, month: 2, value: 1.452999 },
    { year: 2018, month: 3, value: 1.467995 },
    { year: 2018, month: 4, value: 1.480735 },
    { year: 2018, month: 5, value: 1.489403 },
    { year: 2018, month: 6, value: 1.494842 },
    { year: 2018, month: 7, value: 1.503007 },
    { year: 2018, month: 8, value: 1.511413 },
    { year: 2018, month: 9, value: 1.520655 },
    { year: 2018, month: 10, value: 1.528333 },
    { year: 2018, month: 11, value: 1.536745 },
    { year: 2018, month: 12, value: 1.547607 },
    { year: 2019, month: 1, value: 1.558894 },
    { year: 2019, month: 2, value: 1.572972 },
    { year: 2019, month: 3, value: 1.588020 },
    { year: 2019, month: 4, value: 1.605697 },
    { year: 2019, month: 5, value: 1.620058 },
    { year: 2019, month: 6, value: 1.632867 },
    { year: 2019, month: 7, value: 1.640880 },
    { year: 2019, month: 8, value: 1.650409 },
    { year: 2019, month: 9, value: 1.658714 },
    { year: 2019, month: 10, value: 1.669511 },
    { year: 2019, month: 11, value: 1.679474 },
    { year: 2019, month: 12, value: 1.692338 },
    { year: 2020, month: 1, value: 1.710610 },
    { year: 2020, month: 2, value: 1.733428 },
    { year: 2020, month: 3, value: 1.755769 },
    { year: 2020, month: 4, value: 1.772547 },
    { year: 2020, month: 5, value: 1.785292 },
    { year: 2020, month: 6, value: 1.796809 },
    { year: 2020, month: 7, value: 1.808690 },
    { year: 2020, month: 8, value: 1.821844 },
    { year: 2020, month: 9, value: 1.836152 },
    { year: 2020, month: 10, value: 1.851810 },
    { year: 2020, month: 11, value: 1.868427 },
    { year: 2020, month: 12, value: 1.889874 },
    { year: 2021, month: 1, value: 1.916818 },
    { year: 2021, month: 2, value: 1.950204 },
    { year: 2021, month: 3, value: 1.984458 },
    { year: 2021, month: 4, value: 2.017302 },
    { year: 2021, month: 5, value: 2.045016 },
    { year: 2021, month: 6, value: 2.067849 },
    { year: 2021, month: 7, value: 2.089868 },
    { year: 2021, month: 8, value: 2.112812 },
    { year: 2021, month: 9, value: 2.137319 },
    { year: 2021, month: 10, value: 2.157984 },
    { year: 2021, month: 11, value: 2.175781 },
    { year: 2021, month: 12, value: 2.193245 },
    { year: 2022, month: 1, value: 2.216195 },
    { year: 2022, month: 2, value: 2.243403 },
    { year: 2022, month: 3, value: 2.272777 },
    { year: 2022, month: 4, value: 2.296384 },
    { year: 2022, month: 5, value: 2.310893 },
    { year: 2022, month: 6, value: 2.313013 },
    { year: 2022, month: 7, value: 2.304834 },
    { year: 2022, month: 8, value: 2.289026 },
    { year: 2022, month: 9, value: 2.269879 },
    { year: 2022, month: 10, value: 2.249631 },
    { year: 2022, month: 11, value: 2.232530 },
    { year: 2022, month: 12, value: 2.215217 },
    { year: 2023, month: 1, value: 2.199791 },
    { year: 2023, month: 2, value: 2.184304 },
    { year: 2023, month: 3, value: 2.172402 },
    { year: 2023, month: 4, value: 2.162775 },
    { year: 2023, month: 5, value: 2.155718 },
    { year: 2023, month: 6, value: 2.149328 },
    { year: 2023, month: 7, value: 2.145119 },
    { year: 2023, month: 8, value: 2.151227 },
    { year: 2023, month: 9, value: 2.157527 },
    { year: 2023, month: 10, value: 2.159171 },
    { year: 2023, month: 11, value: 2.135270 },
    { year: 2023, month: 12, value: 2.110791 },
    { year: 2024, month: 1, value: 2.090579 },
    { year: 2024, month: 2, value: 2.088467 },
    { year: 2024, month: 3, value: 2.091640 },
    { year: 2024, month: 4, value: 2.099316 },
    { year: 2024, month: 5, value: 2.109062 },
    { year: 2024, month: 6, value: 2.118539 },
    { year: 2024, month: 7, value: 2.129404 },
    { year: 2024, month: 8, value: 2.137438 },
    { year: 2024, month: 9, value: 2.151551 }
  ],
  [ObjectType.MultiFamilyDwelling]: [
    { year: 2012, month: 6, value: 0.982369 },
    { year: 2012, month: 7, value: 0.988979 },
    { year: 2012, month: 8, value: 0.993948 },
    { year: 2012, month: 9, value: 0.997480 },
    { year: 2012, month: 10, value: 0.995752 },
    { year: 2012, month: 11, value: 0.996629 },
    { year: 2012, month: 12, value: 0.997033 },
    { year: 2013, month: 1, value: 1.000000 },
    { year: 2013, month: 2, value: 1.002246 },
    { year: 2013, month: 3, value: 1.007544 },
    { year: 2013, month: 4, value: 1.016776 },
    { year: 2013, month: 5, value: 1.026610 },
    { year: 2013, month: 6, value: 1.035460 },
    { year: 2013, month: 7, value: 1.038638 },
    { year: 2013, month: 8, value: 1.041795 },
    { year: 2013, month: 9, value: 1.043155 },
    { year: 2013, month: 10, value: 1.045084 },
    { year: 2013, month: 11, value: 1.044752 },
    { year: 2013, month: 12, value: 1.047906 },
    { year: 2014, month: 1, value: 1.052097 },
    { year: 2014, month: 2, value: 1.061188 },
    { year: 2014, month: 3, value: 1.070347 },
    { year: 2014, month: 4, value: 1.079867 },
    { year: 2014, month: 5, value: 1.083505 },
    { year: 2014, month: 6, value: 1.083051 },
    { year: 2014, month: 7, value: 1.079296 },
    { year: 2014, month: 8, value: 1.077735 },
    { year: 2014, month: 9, value: 1.078007 },
    { year: 2014, month: 10, value: 1.084954 },
    { year: 2014, month: 11, value: 1.093623 },
    { year: 2014, month: 12, value: 1.099824 },
    { year: 2015, month: 1, value: 1.098662 },
    { year: 2015, month: 2, value: 1.095003 },
    { year: 2015, month: 3, value: 1.098299 },
    { year: 2015, month: 4, value: 1.107402 },
    { year: 2015, month: 5, value: 1.120691 },
    { year: 2015, month: 6, value: 1.130480 },
    { year: 2015, month: 7, value: 1.139525 },
    { year: 2015, month: 8, value: 1.146131 },
    { year: 2015, month: 9, value: 1.150594 },
    { year: 2015, month: 10, value: 1.156014 },
    { year: 2015, month: 11, value: 1.161366 },
    { year: 2015, month: 12, value: 1.169610 },
    { year: 2016, month: 1, value: 1.176124 },
    { year: 2016, month: 2, value: 1.185054 },
    { year: 2016, month: 3, value: 1.193483 },
    { year: 2016, month: 4, value: 1.202825 },
    { year: 2016, month: 5, value: 1.207780 },
    { year: 2016, month: 6, value: 1.212625 },
    { year: 2016, month: 7, value: 1.214991 },
    { year: 2016, month: 8, value: 1.217023 },
    { year: 2016, month: 9, value: 1.216990 },
    { year: 2016, month: 10, value: 1.215717 },
    { year: 2016, month: 11, value: 1.220306 },
    { year: 2016, month: 12, value: 1.224991 },
    { year: 2017, month: 1, value: 1.231576 },
    { year: 2017, month: 2, value: 1.236575 },
    { year: 2017, month: 3, value: 1.243280 },
    { year: 2017, month: 4, value: 1.253556 },
    { year: 2017, month: 5, value: 1.261381 },
    { year: 2017, month: 6, value: 1.268397 },
    { year: 2017, month: 7, value: 1.272847 },
    { year: 2017, month: 8, value: 1.278550 },
    { year: 2017, month: 9, value: 1.287469 },
    { year: 2017, month: 10, value: 1.301276 },
    { year: 2017, month: 11, value: 1.316629 },
    { year: 2017, month: 12, value: 1.335664 },
    { year: 2018, month: 1, value: 1.352728 },
    { year: 2018, month: 2, value: 1.371681 },
    { year: 2018, month: 3, value: 1.385949 },
    { year: 2018, month: 4, value: 1.395834 },
    { year: 2018, month: 5, value: 1.403314 },
    { year: 2018, month: 6, value: 1.410382 },
    { year: 2018, month: 7, value: 1.427472 },
    { year: 2018, month: 8, value: 1.447765 },
    { year: 2018, month: 9, value: 1.467735 },
    { year: 2018, month: 10, value: 1.479109 },
    { year: 2018, month: 11, value: 1.483132 },
    { year: 2018, month: 12, value: 1.488863 },
    { year: 2019, month: 1, value: 1.491497 },
    { year: 2019, month: 2, value: 1.499435 },
    { year: 2019, month: 3, value: 1.499236 },
    { year: 2019, month: 4, value: 1.502000 },
    { year: 2019, month: 5, value: 1.496433 },
    { year: 2019, month: 6, value: 1.494353 },
    { year: 2019, month: 7, value: 1.497106 },
    { year: 2019, month: 8, value: 1.512566 },
    { year: 2019, month: 9, value: 1.535845 },
    { year: 2019, month: 10, value: 1.559957 },
    { year: 2019, month: 11, value: 1.579959 },
    { year: 2019, month: 12, value: 1.596185 },
    { year: 2020, month: 1, value: 1.609591 },
    { year: 2020, month: 2, value: 1.623831 },
    { year: 2020, month: 3, value: 1.637094 },
    { year: 2020, month: 4, value: 1.653793 },
    { year: 2020, month: 5, value: 1.669657 },
    { year: 2020, month: 6, value: 1.687641 },
    { year: 2020, month: 7, value: 1.693032 },
    { year: 2020, month: 8, value: 1.698946 },
    { year: 2020, month: 9, value: 1.701535 },
    { year: 2020, month: 10, value: 1.720160 },
    { year: 2020, month: 11, value: 1.736293 },
    { year: 2020, month: 12, value: 1.761456 },
    { year: 2021, month: 1, value: 1.781346 },
    { year: 2021, month: 2, value: 1.814921 },
    { year: 2021, month: 3, value: 1.848010 },
    { year: 2021, month: 4, value: 1.889051 },
    { year: 2021, month: 5, value: 1.923887 },
    { year: 2021, month: 6, value: 1.953666 },
    { year: 2021, month: 7, value: 1.975601 },
    { year: 2021, month: 8, value: 1.996022 },
    { year: 2021, month: 9, value: 2.024275 },
    { year: 2021, month: 10, value: 2.063296 },
    { year: 2021, month: 11, value: 2.100564 },
    { year: 2021, month: 12, value: 2.134921 },
    { year: 2022, month: 1, value: 2.153894 },
    { year: 2022, month: 2, value: 2.174569 },
    { year: 2022, month: 3, value: 2.184707 },
    { year: 2022, month: 4, value: 2.201475 },
    { year: 2022, month: 5, value: 2.218401 },
    { year: 2022, month: 6, value: 2.230071 },
    { year: 2022, month: 7, value: 2.224261 },
    { year: 2022, month: 8, value: 2.199746 },
    { year: 2022, month: 9, value: 2.175565 },
    { year: 2022, month: 10, value: 2.153833 },
    { year: 2022, month: 11, value: 2.131559 },
    { year: 2022, month: 12, value: 2.110269 },
    { year: 2023, month: 1, value: 2.088022 },
    { year: 2023, month: 2, value: 2.071797 },
    { year: 2023, month: 3, value: 2.054119 },
    { year: 2023, month: 4, value: 2.040052 },
    { year: 2023, month: 5, value: 2.022537 },
    { year: 2023, month: 6, value: 2.004257 },
    { year: 2023, month: 7, value: 1.983814 },
    { year: 2023, month: 8, value: 1.972057 },
    { year: 2023, month: 9, value: 1.965250 },
    { year: 2023, month: 10, value: 1.964132 },
    { year: 2023, month: 11, value: 1.961795 },
    { year: 2023, month: 12, value: 1.959919 },
    { year: 2024, month: 1, value: 1.959654 },
    { year: 2024, month: 2, value: 1.959322 },
    { year: 2024, month: 3, value: 1.959050 },
    { year: 2024, month: 4, value: 1.957007 },
    { year: 2024, month: 5, value: 1.959024 },
    { year: 2024, month: 6, value: 1.958154 },
    { year: 2024, month: 7, value: 1.958765 },
    { year: 2024, month: 8, value: 1.952628 },
    { year: 2024, month: 9, value: 1.956680 }
  ],
};

export const germanyIndexCompareValues: Record<IndexIdentifier, IndexCategoryValue | undefined> = {
  [IndexIdentifier.SIM]: simData,
  [IndexIdentifier.IPI]: ipiData,
  [IndexIdentifier.IPN]: undefined,
  [IndexIdentifier.SEPI]: undefined,
};
