import { Suspense, lazy } from "react";

import { useCurrentPage, useHandlePageChange } from "@/hooks";
import { PageType } from "@/types/pageTypes";

import { CurrentPageFallback } from "./CurrentPageFallback";

const AdminLoginPage = lazy(() =>
  import("@/pages/adminLoginPage").then((module) => ({ default: module.AdminLoginPage })),
);

const InstitutionPage = lazy(() =>
  import("@/pages/backOfficeInstitutionPage").then((module) => ({ default: module.InstitutionPage })),
);

const UserPage = lazy(() => import("@/pages/backOfficeUserPage").then((module) => ({ default: module.UserPage })));

const SubscriptionPage = lazy(() =>
  import("@/pages/backOfficeSubscriptionPage").then((module) => ({ default: module.SubscriptionPage })),
);

const UserHistoryPage = lazy(() =>
  import("@/pages/backOfficeUserHistoryPage").then((module) => ({ default: module.UserHistoryPage })),
);

const IndicesPage = lazy(() =>
  import("@/pages/backOfficeIndicesPage").then((module) => ({ default: module.IndicesPage })),
);

export const AdminPage = (): JSX.Element => {
  const [currentPage] = useCurrentPage();
  useHandlePageChange();

  const renderPage = (page: PageType): JSX.Element => {
    switch (page) {
      case PageType.AdminLogin:
        return <AdminLoginPage />;
      case PageType.BackOfficeInstitution:
        return <InstitutionPage />;
      case PageType.BackOfficeUser:
        return <UserPage />;
      case PageType.BackOfficeSubscription:
        return <SubscriptionPage />;
      case PageType.BackOfficeUserHistory:
        return <UserHistoryPage />;
      case PageType.BackOfficeIndices:
        return <IndicesPage />;
      default:
        return <AdminLoginPage />;
    }
  };

  return <Suspense fallback={<CurrentPageFallback />}>{renderPage(currentPage)}</Suspense>;
};
