import { getDefaultFilterForIndex } from "@/helpers/playgroundFilterHelper";
import { ChartSeries, PlaygroundFilter } from "@/types/playground";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

// Define a type for the slice state
export interface PlaygroundPageSliceState {
  filter: PlaygroundFilter;
  chartSeries: ChartSeries[];
  germanyCompareSeries: ChartSeries[];
  chartDeadlineInformation?: string;
  referenceYear: DateTime;
}

// Define the initial state using that type
const initialState: PlaygroundPageSliceState = {
  filter: getDefaultFilterForIndex(),
  chartSeries: [],
  germanyCompareSeries: [],
  chartDeadlineInformation: undefined,
  referenceYear: DateTime.fromJSDate(new Date(2005, 1, 1))
};

// Implement the slice
export const playgroundPageSlice = createSlice({
  name: "playgroundPage",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PlaygroundFilter>) => {
      state.filter = action.payload;
    },
    setChartSeries: (state, action: PayloadAction<ChartSeries[]>) => {
      state.chartSeries = action.payload;
    },
    setGermanyCompareSeries: (state, action: PayloadAction<ChartSeries[]>) => {
      state.germanyCompareSeries = action.payload;
    },
    setChartDeadlineInformation: (state, action: PayloadAction<string | undefined>) => {
      state.chartDeadlineInformation = action.payload;
    },
    setReferenceYear: (state, action: PayloadAction<DateTime>) => {
      state.referenceYear = action.payload;
    }
  }
});

export const { setFilter, setChartSeries, setChartDeadlineInformation, setReferenceYear, setGermanyCompareSeries } = playgroundPageSlice.actions;

export const PlaygroundPageReducer = playgroundPageSlice.reducer;
