export enum DateTimeFormat {
  Full = "full",
  Time = "time",
  Date = "date",
}

export enum DateTimeStyle {
  Full = "full",
  Long = "long",
  Medium = "medium",
  Short = "short",
}
