import { sprengConnectApi } from "../sprengConnectApi";
import { PaginationResponse, SprengConnectApiResponse } from "../types";
import {
  BatchCsvOutputResponse,
  BatchOutputResponse,
  BatchResponse,
  BatchStatusResponse,
  BatchUploadRequest,
  GetBatchesParameters,
} from "../types/batchController";

export const batchController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpload: builder.mutation<SprengConnectApiResponse<BatchResponse[]> | undefined, BatchUploadRequest>({
      query: (body) => ({
        url: "batches/Batch",
        method: "POST",
        data: body,
      }),
      //invalidatesTags: no invalidate tags, because always new data
    }),
    getBatchStatuses: builder.query<SprengConnectApiResponse<BatchStatusResponse[]> | undefined, string[]>({
      query: (batchIds) => ({
        url: "batches/Status?uuids=" + batchIds.join(","),
        method: "GET",
      }),
      //providesTags: no provide tags, because always new data
    }),
    getBatchOutput: builder.query<SprengConnectApiResponse<BatchOutputResponse[]> | undefined, string>({
      query: (batchId) => ({
        url: `batches/${batchId}/Result`,
        method: "GET",
      }),
      //providesTags: no provide tags, because we don't know if there is data or not and we don't want to block the query
    }),
    getBatchCsvOutput: builder.query<SprengConnectApiResponse<BatchCsvOutputResponse> | undefined, string>({
      query: (batchId) => ({
        url: `batches/${batchId}/ResultCsv`,
        method: "GET",
      }),
      //providesTags: no provide tags, because we don't know if there is data or not and we don't want to block the query
    }),
    getBatches: builder.query<PaginationResponse<BatchResponse> | undefined, GetBatchesParameters>({
      query: (query) => ({
        url: "batches/Batch",
        method: "GET",
        params: query,
      }),
      //providesTags: no provide tags, because always new
    }),
  }),
});

export const {
  useBatchUploadMutation,
  useGetBatchStatusesQuery,
  useGetBatchOutputQuery,
  useGetBatchCsvOutputQuery,
  useGetBatchesQuery,

  useLazyGetBatchOutputQuery,
  useLazyGetBatchesQuery,
} = batchController;
