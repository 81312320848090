
export type UseDownloadFileReturnType = {
  downloadBlob: (blob: Blob, fileName: string) => void;
  downloadFileString: (fileString: string, filename: string) => void;
};

export const useDownloadFile = (): UseDownloadFileReturnType => {

  const downloadFile = (linkHref: string, fileName: string): void => {
    const link = document.createElement("a");
    link.href = linkHref;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadBlob = (blob: Blob, fileName: string): void => {
    downloadFile(URL.createObjectURL(blob), fileName);
  };

  const downloadFileString = (fileString: string, fileName: string): void => {
    downloadFile(fileString, fileName);
  };

  return {
    downloadBlob,
    downloadFileString
  }
};
