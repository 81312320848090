import { SvgIcon, SvgIconProps } from "@mui/material";

interface ChevronDownIconProps extends SvgIconProps {
  rotate?: number;
}

export const ChevronDownIcon = ({ rotate = 0, ...props }: ChevronDownIconProps): JSX.Element => (
  <SvgIcon
    width="16"
    height="9"
    sx={{ width: "16px", height: "9px", transform: `rotate(${rotate}deg)` }}
    {...props}
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L8 8L15 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
);
