import { ChartSeries } from "@/types";
import { formatFloat } from "./formatStringHelpers";
import { ApiResponse, IndexChartDataResponse, PostGenerateIndexChartDataRequest } from "@/api/sprengConnectApi";
import { TFunction } from "i18next";

const calcReferenceYearValue = (referenceYearValue: number, currentYearValue: number): number => {
  return (currentYearValue - referenceYearValue) / referenceYearValue * 100;
};

export const calcReferenceYearValues = (referenceYear: number, chartSeries: ChartSeries): (string | number)[] => {
  const seriesHasMonthValues = chartSeries.values.some(value => value.month != null);
  const referenceYearValue = chartSeries.values.find(value => value.year === referenceYear && (!seriesHasMonthValues || value.month === value.month));
  if (!referenceYearValue) return chartSeries.values.map(() => "-");

  return chartSeries.values.map(value => referenceYearValue.y == null || value.y == null ? "-" : formatFloat(calcReferenceYearValue(referenceYearValue.y, value.y), false));
}

export const getSeriesName = (t: TFunction<"PlaygroundPage", undefined>, request: PostGenerateIndexChartDataRequest, indexChartData?: ApiResponse<IndexChartDataResponse>): string => {
  let seriesName = `${request.category} - `;
  if (indexChartData == null) {
    seriesName += t("chart.seriesLoadingError");
    return seriesName;
  }

  const firstRegion = indexChartData.data.values.find((value) => value.region != null)?.region;
  seriesName += `${request.address.zip}${firstRegion != null ? ` ${firstRegion}` : ""}`;

  if (request.energyEfficiencyGroup) {
    seriesName += ` - ${t("filter.energyEfficiencySection.options", { context: request.energyEfficiencyGroup })}`;
  }

  if (request.objectState) {
    seriesName += ` - ${t("filter.objectStateSection.options", { context: request.objectState })}`;
  }

  if (request.timeResolution) {
    seriesName += ` - ${t("filter.timeResolutionSection.options", { context: request.timeResolution })}`;
  }

  if (request.expenseType) {
    seriesName += ` - ${t("filter.expenseTypeSection.options", { context: request.expenseType })}`;
  }

  return seriesName;
}
