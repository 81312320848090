import { IndexIdentifier, SprengnetterIndexOverviewItemResponse } from "@/api/sprengConnectApi";
import { CompareType, EnergyEfficiencyGroup, ExpenseType, LocationResolutionType, ObjectState, ObjectType, PlaygroundFilter, TimeResolution } from "@/types";

export const isTimeResolutionSectionShown = (index: SprengnetterIndexOverviewItemResponse): boolean =>
  index.identifier != null && [IndexIdentifier.SIM].includes(index.identifier);

export const isEnergyEfficiencyGroupSectionShown = (index: SprengnetterIndexOverviewItemResponse): boolean =>
  index.identifier != null && [IndexIdentifier.SEPI].includes(index.identifier);

export const isExpenseTypeSectionShown = (index: SprengnetterIndexOverviewItemResponse): boolean =>
  index.identifier != null && [IndexIdentifier.IPI, IndexIdentifier.IPN].includes(index.identifier);

export const isCompareTypeSectionShown = (index: SprengnetterIndexOverviewItemResponse): boolean =>
  index.identifier != null && ![IndexIdentifier.IPN].includes(index.identifier);

export const isObjectStateSectionShown = (index: SprengnetterIndexOverviewItemResponse): boolean =>
  index.identifier != null && [IndexIdentifier.IPN].includes(index.identifier);

export const getObjectTypeForIndex = (index?: SprengnetterIndexOverviewItemResponse): ObjectType[] => {
  if (index == null) {
    return [];
  }
  const options: ObjectType[] = [ObjectType.OneFamilyDwelling];
  if (index.identifier !== IndexIdentifier.IPN) {
    options.push(ObjectType.MultiFamilyDwelling);
  }
  options.push(ObjectType.OwnerOccupiedDwelling);
  return options;
};

export const defaultPlaygroundFilter: PlaygroundFilter = {
  locationResolution: {
    metadata: {},
    type: LocationResolutionType.PostalCode
  },
  objectTypes: [ObjectType.OneFamilyDwelling, ObjectType.MultiFamilyDwelling, ObjectType.OwnerOccupiedDwelling],
  compareType: CompareType.Germany,
  timeResolution: TimeResolution.Yearly,
  energyEfficiencyGroups: [EnergyEfficiencyGroup.High, EnergyEfficiencyGroup.Medium],
  expenseTypes: [ExpenseType.Buy],
  objectStates: [ObjectState.NewBuilding]
};

export const getDefaultFilterForIndex = (index?: SprengnetterIndexOverviewItemResponse): PlaygroundFilter => {
  if (index == null) {
    return defaultPlaygroundFilter;
  }

  const adjustedFilter: PlaygroundFilter = { ...defaultPlaygroundFilter };
  const availableObjectTypes = getObjectTypeForIndex(index);
  adjustedFilter.objectTypes = adjustedFilter.objectTypes.filter(type => availableObjectTypes.includes(type));

  if (!isTimeResolutionSectionShown(index)) {
    adjustedFilter.timeResolution = undefined;
  }

  if (!isEnergyEfficiencyGroupSectionShown(index)) {
    adjustedFilter.energyEfficiencyGroups = undefined;
  }

  if (!isExpenseTypeSectionShown(index)) {
    adjustedFilter.expenseTypes = undefined;
  }

  if (!isCompareTypeSectionShown(index)) {
    adjustedFilter.compareType = undefined;
  }

  if (!isObjectStateSectionShown(index)) {
    adjustedFilter.objectStates = undefined;
  }

  return adjustedFilter;
}

export const isFilterMissingValues = (filter: PlaygroundFilter): boolean => {
  return filter.locationResolution == null || (filter.locationResolution.type === LocationResolutionType.PostalCode && (filter.locationResolution.metadata.postalCodes?.length ?? 0) === 0);
};
