import { TagType } from "@/api/enums";

import { sprengConnectApi } from "../sprengConnectApi";
import {
  PaginationResponse,
  SprengnetterIndexOverviewItemResponse,
} from "../types";

export const indexOverviewController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    indexOverview_getIndexes: builder.query<
      PaginationResponse<SprengnetterIndexOverviewItemResponse> | undefined,
      void
    >({
      query: () => ({
        url: "IndexOverview/GetIndexes",
        method: "GET"
      }),
      providesTags: () => [TagType.SprengConnectApi_SprengnetterIndexOverviewItemResponse]
    }),
  }),
});

export const {
  useIndexOverview_getIndexesQuery
} = indexOverviewController;
