//#region react-router-dom types
import { RouteObject } from "react-router-dom";

import { RequireOne } from "./general";

// some types not exported by react-router-dom :-(
export type ImmutableRouteKey = "lazy" | "caseSensitive" | "path" | "id" | "index" | "children";

export type LazyFunctionReturnType = RequireOne<Omit<RouteObject, ImmutableRouteKey>>;
//#endregion react-router-dom types

export enum RouteType {
  AppBaseLayout = "AppBaseLayout",
  CurrentPage = "CurrentPage",
  NotFound = "NotFound",
  TestPage = "TestPage",
  AdminPage = "AdminPage",
}

export type RouteHandle = {
  type: RouteType;
  publicRoute?: boolean;
};

export const isRouteHandle = <T>(obj: T | RouteHandle): obj is RouteHandle => (obj as RouteHandle)?.type != null;

export const routeTypesWithoutTranslation = [RouteType.AppBaseLayout] as const;

type RouteTypesWithoutTranslationTypes = (typeof routeTypesWithoutTranslation)[number];
export type RoutesExcludingWithoutTranslation = Exclude<RouteType, RouteTypesWithoutTranslationTypes>;

export const isRouteTypeWithTranslation = (type: RouteType): type is RoutesExcludingWithoutTranslation =>
  !(routeTypesWithoutTranslation as ReadonlyArray<RouteType>).includes(type);
