import { TagType } from "@/api/enums";

import { sprengConnectApi } from "../sprengConnectApi";
import { ApiResponse, IndexChartDataResponse, PostGenerateIndexChartDataRequest } from "../types";

export const playgroundController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    playground_generateIndexChartData: builder.query<
      ApiResponse<IndexChartDataResponse> | undefined,
      PostGenerateIndexChartDataRequest
    >({
      query: (request) => ({
        url: "Playground/GenerateIndexChartData",
        method: "post",
        data: request,
      }),
      providesTags: () => [TagType.SprengConnectApi_IndexChartDataResponse]
    }),
  }),
});

export const {
  useLazyPlayground_generateIndexChartDataQuery,
} = playgroundController;
