import { Alert, Box, Button, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IndexIdentifier,
  IndexLinkedDocumentResponse,
  SprengnetterIndexOverviewItemResponse,
} from "@/api/sprengConnectApi";
import { useGetIndexLinkedDocumentsQuery } from "@/api/sprengConnectApi/controllers/backOfficeController";
import { ChevronDownIcon, CsvIcon, DocumentsIcon, InfoIcon, PdfIcon } from "@/assets";
import { useDownloadFile } from "@/hooks";

const currentYear = new Date().getFullYear();

type DownloadDocumentationButtonProps = {
  selectedIndex: SprengnetterIndexOverviewItemResponse;
};

export const DownloadDocumentationButton = ({
  selectedIndex,
}: DownloadDocumentationButtonProps): JSX.Element | undefined => {
  const { t } = useTranslation("PlaygroundPage");
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);

  const {
    data: indexLinkedDocuments,
    isLoading: areIndexLinkedDocumentsLoading,
    isFetching: areIndexLinkedDocumentsFetching,
    isError: areIndexLinkedDocumentsError,
  } = useGetIndexLinkedDocumentsQuery({ indexId: selectedIndex.id });

  const isSim = selectedIndex.identifier === IndexIdentifier.SIM;
  const documentsAreLoading = areIndexLinkedDocumentsLoading || areIndexLinkedDocumentsFetching;
  const hasDocuments = (indexLinkedDocuments?.data?.length ?? 0) > 0;

  const { downloadFileString } = useDownloadFile();

  const handleDownloadCsvPdfClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setMenuAnchorEl(null);
  };

  const handleDownloadReport = (): void => {
    if (selectedIndex.identifier !== IndexIdentifier.SIM) return;
    downloadFileString("/accompanyingDocumentation/Report_S-IM_2024.pdf", "Report_S-IM_2024.pdf");
  };

  const handleDownloadData = (): void => {
    if (selectedIndex.identifier !== IndexIdentifier.SIM) return;
    downloadFileString("/accompanyingDocumentation/Uebergabe_S_IM_Index_2024.csv", "Übergabe_S-IM_Index_2024.csv");
  };

  const handleIndexLinkedDocumentClick = (indexLinkedDocument: IndexLinkedDocumentResponse) => (): void => {
    downloadFileString(indexLinkedDocument.link, indexLinkedDocument.name);
  };

  // currently only sim has hardcoded documentation, so if no documents configured in database, use hardcoded ones
  if (!hasDocuments && !isSim) return;

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DocumentsIcon />}
        endIcon={<ChevronDownIcon />}
        onClick={handleDownloadCsvPdfClick}
      >
        {t("downloadDocumentationButton.downloadDocumentation")}
      </Button>
      <Menu anchorEl={menuAnchorEl} open={open} onClose={handleClose}>
        <Box sx={{ display: "flex", p: 1 }}>
          <Typography variant="menuTitle" sx={{ mr: 1 }}>
            {t("downloadDocumentationButton.title")}
          </Typography>
          <InfoIcon />
        </Box>
        {areIndexLinkedDocumentsError && (
          <Alert severity="error">{t("downloadDocumentationButton.loadingDocumentsError")}</Alert>
        )}
        {documentsAreLoading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <MenuItem key={index}>
              <Skeleton variant="circular" sx={{ mr: 1 }} />
              <Skeleton variant="text" />
            </MenuItem>
          ))
        ) : (
          <>
            {!hasDocuments && isSim && (
              <>
                <MenuItem onClick={handleDownloadReport}>
                  <PdfIcon sx={{ mr: 1 }} />
                  {t("downloadDocumentationButton.report", { indexTitle: selectedIndex.title, currentYear })}
                </MenuItem>
                <MenuItem onClick={handleDownloadData}>
                  <CsvIcon sx={{ mr: 1 }} />
                  {t("downloadDocumentationButton.dataDownload", { indexTitle: selectedIndex.title, currentYear })}
                </MenuItem>
              </>
            )}
            {indexLinkedDocuments?.data.map((document) => (
              <MenuItem key={document.id} onClick={handleIndexLinkedDocumentClick(document)}>
                <PdfIcon sx={{ mr: 1 }} />
                {document.name}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </>
  );
};
